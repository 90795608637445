import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode, faServer, faAngleDoubleDown, faCode, faDatabase, faTools } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

const Skills = () => {
    return (
        <Container className="mt-5 pt-4">
            <h2 className="text-center" style={{ color: 'cyan' }}>Skills Overview</h2>
            <Row className="justify-content-md-center text-white">
                <Col md={8}>
                    <p>
                        Below is a quick overview of my
                        main technical skill sets and tools I use. Want to find out more about my experience?
                        <Link to='/resume' style={{ color: 'cyan' }}> Check out my resume.</Link>
                    </p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={6} xl={4} className="mb-3 ">
                    <div className="bg-white p-2 border rounded box h-100">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faCode} size="4x" />
                            <h3 className="mt-3">Programming Languages</h3>
                        </div>
                        <div className="d-flex justify-content-center border-top pt-3">
                            <p className="d-inline-block">
                                &#10004; TypeScript<br />
                                &#10004; JavaScript<br />
                                &#10004; PHP<br />
                                &#10004; C/C++<br />
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md={6} xl={4} className="mb-3 ">
                    <div className="bg-white p-2 border rounded box h-100">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faServer} size="4x" />
                            <h3 className="mt-3">Web Backend</h3>
                        </div>
                        <div className="d-flex justify-content-center border-top pt-3">
                            <p className="d-inline-block">
                                &#10004; Node.js<br />
                                &#10004; Express.js<br />
                                &#10004; Nest.js<br />
                                &#10004; PHP<br />
                                &#10004; Laravel<br />
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md={6} xl={4} className="mb-3 ">
                    <div className="bg-white p-2 border rounded box h-100">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faLaptopCode} size="4x" />
                            <h3 className="mt-3">Web Frontend</h3>
                        </div>
                        <div className="d-flex justify-content-center border-top pt-3">
                            <p className="d-inline-block">
                                &#10004; React.js<br />
                                &#10004; Bootstrap<br />
                                &#10004; Tailwind CSS<br />
                                &#10004; HTML/CSS<br />
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md={6} xl={4} className="mb-3 ">
                    <div className="bg-white p-2 border rounded box h-100">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faDatabase} size="4x" />
                            <h3 className="mt-3">Database</h3>
                        </div>
                        <div className="d-flex justify-content-center border-top pt-3">
                            <p className="d-inline-block">
                                &#10004; MongoDB<br />
                                &#10004; MySQL<br />
                                &#10004; PostgreSQL<br />
                                &#10004; Redis<br />
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md={6} xl={4} className="mb-3 ">
                    <div className="bg-white p-2 border rounded box h-100">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faTools} size="4x" />
                            <h3 className="mt-3">Tools</h3>
                        </div>
                        <div className="d-flex justify-content-center border-top pt-3">
                            <p className="d-inline-block">
                                &#10004; Git<br />
                                &#10004; Docker<br />
                                &#10004; Jira<br />
                                &#10004; Postman<br />
                                &#10004; CI/CD<br />
                            </p>
                        </div>
                    </div>
                </Col>

                <Col md={6} xl={4} className="mb-3 ">
                    <div className="bg-white p-2 border rounded box h-100">
                        <div className="text-center">
                            <FontAwesomeIcon icon={faAngleDoubleDown} size="4x" />
                            <h3 className="mt-3">Others</h3>
                        </div>
                        <div className="d-flex justify-content-center border-top pt-3">
                            <p className="d-inline-block">
                                &#10004; AWS<br />
                                &#10004; Linux<br />
                                &#10004; REST API<br />
                                &#10004; GraphQL<br />
                                &#10004; Socket.io<br />
                                &#10004; RabbitMQ<br />
                                &#10004; Payment Gateway<br />
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Skills;