import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ProjectDetail from '../ProjectDetail/ProjectDetail';
import projectsData from "../../../ProjectsData/ProjectsData.json";

const ProjectsHome = () => {
    const [projects, setProjects] = useState(projectsData);

    // Load projects data from projectData.json file
    // useEffect(() => {
    //     fetch('https://muhammadrifat.xyz/ProjectsData.json')
    //         .then((res) => res.json())
    //         .then(data => {
    //             setProjects(data);
    //         })
    // }, [])

    const history = useHistory();

    // redirecting projects page
    const handleViewBtn = () => {
        history.push('/projects');
    }
    return (
        <Container className="mt-5 pt-4">
            <h2 className="text-center mb-4" style={{ color: 'cyan' }}>Featured Projects</h2>
            <Row>
                {
                    projects.slice(0, 4).map(project => <ProjectDetail project={project} key={project.id}></ProjectDetail>)
                }
            </Row>
            <div className="text-center mb-2">
                <button className="btn btn-outline-light mt-3" onClick={handleViewBtn}>View all projects</button>
            </div>
        </Container>
    );
};

export default ProjectsHome;