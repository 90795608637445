import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import './HeaderMain.css';
import profileImage from '../../../../images/profile.png';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Typical from 'react-typical'

const HeaderMain = () => {
    const history = useHistory();
    const handleClick = () => {
        history.push('/contact');
    }
    return (
        <>
            <Row className="text-white mt-5 pt-4">
                <Col md={{ span: 5, offset: 1, order: 'last' }} className="mb-4">
                    <Image src={profileImage} alt="" fluid style={{ width: '90%', height: '98%', marginLeft: '10px' }} />
                </Col>
                <Col md={{ span: 6, order: 'first' }}>
                    <p className="t-color">Hello, I'm</p>

                    {/* used for typing animation of text */}
                    <Typical
                        steps={['M', 1000, 'Muhammad Rifat', 500]}
                        loop={1}
                        wrapper="h2"
                    />
                    <ul type="square" className="d-flex mr-4">
                        <li className="t-color">Software Engineer <span className="text-white">|</span> Backend Engineer</li>
                    </ul>
                    <p className="text-justify">
                        An enthusiastic Programmer who is driven by an unwavering passion for the field of software development. Proven ability to design, develop, and deploy high-performance systems and learn new technologies quickly. Also have strong problem-solving, analytical, and teamwork skills. The goal is continuously to enhance skills, strengthen knowledge, and explore new technologies.
                    </p>
                    <button className="btn btn-outline-light mt-3" onClick={handleClick}><FontAwesomeIcon icon={faEnvelope} /> Contact me</button>
                    <a className="btn btn-outline-light mt-3 ml-3" href="https://drive.google.com/uc?export=download&id=10kwb3yUSWT5EwEbTHq5JFWUzQHmf_v1C"><FontAwesomeIcon icon={faDownload} /> Download resume</a>
                </Col>
            </Row>
        </>
    );
};

export default HeaderMain;