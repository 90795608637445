import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const Resume = () => {
    return (
        <Container className="mt-4">

            {/* viewing pdf file of my resume */}

            <Row className="d-flex justify-content-center">
                <Col lg={10}>

                    <Row className="mb-2">
                        <Col>
                            <h2 style={{ color: 'cyan' }}>My Resume</h2>
                        </Col>
                        <Col className="text-right">
                            <a className="btn btn-outline-light" href="https://drive.google.com/uc?export=download&id=10kwb3yUSWT5EwEbTHq5JFWUzQHmf_v1C"><FontAwesomeIcon icon={faDownload} /> Download resume</a>
                        </Col>
                    </Row>

                    <iframe src="https://rifat.asqp.online/assets/resume/Rifat_Mia_Resume.pdf" width="100%" height="2100px" frameBorder="0" />
                </Col>
            </Row>
        </Container>
    );
};

export default Resume;